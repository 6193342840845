@import "../../dimens.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
.avatarContainer {
  position: relative;
  border: 0.2rem solid transparent;
  border-radius: 0.4rem;
  width: 9rem;
  margin: 0 auto #{$margins-green + rem} auto;
  cursor: pointer;

  &:focus,
  &:hover {
    > img,
    > svg:first-child {
      outline: 4px solid var(--accent-color);
    }

    > div {
      background: var(--accent-color);
      box-shadow: 0 0 1.2rem var(--accent-color);

      svg {
        color: #ffffff;
      }
    }
  }
}

.mainAccount {
  color: var(--text2-color);
  font-size: calc(var(--text2-size) * 1rem);
  font-weight: var(--text2-weight);
  font-family: var(--text2-font);
  padding: 0.2rem; /*For alignments with card borders*/
  margin-bottom: #{$margins-darkred + rem};
}
.labelSecure {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem;
  color: var(--labelSecure-color);
  background-color: var(--labelSecure-background);
  padding: 0.8rem 0.7rem;
  border-radius: 0.6rem;
  margin-top: 1rem;
  width: fit-content;

  @media (min-width: #{$breakpoints-tablet}px) {
    font-size: 12px;
    line-height: 14px;
  }
}
.editIconContainer {
  width: 3rem;
  height: 3rem;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid var(--accent-color);
  border-radius: 50%;
  background: var(--card-inactive-background);
  svg {
    width: 70%;
    color: var(--accent-color);
  }
}

.buttonRow {
  margin-top: #{$margins-green + rem};
}

.card {
  background-color: var(--card-inactive-background);
  border: 0.2rem solid var(--card-inactive-border);
  border-radius: calc(var(--card-radius) * 1rem);
  padding: #{$margins-green + rem};
}

.focusableCardRow {
  cursor: pointer;
  margin-top: #{$margins-green + rem};
  border: 0.2rem solid var(--card-inactive-border);
  border-radius: calc(var(--card-radius) * 1rem);
  &:focus-within {
    border-color: var(--card-active-border);
  }
}

.separator {
  height: 0.1rem;
  background-color: var(--separator-color);
  margin: #{$margins-green + rem} 0;
}

.pegiContainer {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  svg {
    margin-right: #{$margins-orange + rem};
  }

  justify-content: space-between;
}

.pegiTitle {
  color: var(--text-color);
  font-size: calc(var(--text2-size) * 1rem);
  font-weight: 600;
  font-family: var(--text2-font);
}

.secureCheckbox {
  margin-top: #{$margins-green + rem};
}

.pinContainer {
  margin-top: #{$margins-green + rem};
}

.trashContainer {
  margin: 0 auto;
  margin-top: 1rem;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: var(--card-inactive-background);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s; /* Animation */

  > svg {
    width: 3rem;
    height: 3rem;
    color: var(--card-inactive-textColorLight);
  }

  &:hover,
  &:focus {
    background-color: var(--button-primary-active-background);
    box-shadow: 0 0 1.2rem var(--button-primary-active-background);
    transform: scale(var(--button-primary-scaleFactor));
    svg {
      color: var(--button-primary-active-color);
    }
  }
}

.hidden {
  visibility: hidden;
  height: 0;
}
.noMargin {
  margin: 0;
}
