@import "../../../dimens.scss";
@import "../styles.module.scss";
.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: #{$margins-darkred + rem};
  &.scrollable {
    padding: 0 #{$contentPaddingV-phone-portrait + rem}; /*Extra padding added since margin = 0*/
  }
}

.title {
  font-weight: var(--section-text-weight);
  color: var(--section-text-color);
  font-size: calc(var(--section-text-size) * 1rem);
  font-family: var(--section-text-font);
  display: flex;
  align-items: center;

  > svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.4rem;
  }

  @media screen and (min-width: #{$breakpoints-desktop}px) {
    > svg {
      display: none;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  &.clickable {
    &:hover,
    &:focus {
      > svg {
        display: inline;
      }
      cursor: pointer;
      color: var(--accent-color);
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.list {
  display: flex;
  margin: 0;
  list-style: none;
  flex-wrap: wrap;
  display: grid;
  grid-gap: #{$margins-darkred + rem};
  grid-auto-flow: column;
  grid-template-rows: auto auto;
  padding: 0;
  max-width: 100vw;
  &.scrollable {
    overflow-y: hidden;
    overflow-x: scroll;
    overflow-x: overlay;
    // Extra padding added since margin = 0
    // Magic number 10px is for border on focus overflowing
    padding: 10px #{$contentPaddingV-phone-portrait + rem};
    margin: -10px 0;
    scroll-snap-type: x proximity;
  }
  @media (orientation: landscape) {
    max-width: calc(100vw - #{$navigation-bar-width-phone-landscape + rem});
  }
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    max-width: calc(100vw - #{$navigation-bar-width-desktop + rem});
  }
}

.tile {
  &.featured {
    grid-area: 1 / 1 / span 2 / span 2;
  }
}
