:global {
  .lowend {
    :local {
      .button,
      .primaryButton,
      .secondaryButton,
      .tertiaryButton {
        transition: unset;
      }
    }
  }
}
