@import "../../dimens.scss";

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: #{$margins-green + rem};
  bottom: #{$margins-green + rem};
  z-index: 102;
  right: #{$margins-green + rem};
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-top: 1rem;
    &:first-child {
      margin-top: 0;
    }
  }
}
