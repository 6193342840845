@import "../../dimens.scss";

.container,
.isOnTv,
.isOnMobil {
  background-color: var(--bottombar-background-color);
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  overflow-y: scroll;

  @media (orientation: landscape) {
    bottom: 0;
    left: 0;
    right: initial;
    top: #{$header-height-phone + rem};
    min-height: calc(100vh - #{$header-height-phone + rem});
    width: #{$navigation-bar-width-phone-landscape + rem};
    padding: 0;
    border-top: none;
    border-right: calc(var(--bottombar-border-width) * 1rem) solid
      var(--bottombar-border-color);
  }
}

.isOnMobil {
  min-height: #{$navigation-bar-height-phone + rem};
  display: flex;
  flex-direction: row;
  padding: 1rem #{$margins-darkred + rem} calc(0.8rem + env(safe-area-inset-bottom)) #{$margins-darkred +
    rem};
  border-top: calc(var(--bottombar-border-width) * 1rem) solid
    var(--bottombar-border-color);

  svg {
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    color: var(--bottombar-tint-color-inactive);
    transition: all 0.2s;
    /* Animation */
  }

  @media (orientation: landscape) {
    flex-direction: column;
    justify-content: center;
  }
  @media (orientation: landscape) and (max-height: #{$breakpoints-tablet}px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.container,
.isOnTv {
  padding: 0;
  border-top: calc(var(--bottombar-border-width) * 1rem) solid
    var(--bottombar-border-color);
  min-height: #{$navigation-bar-height-phone + rem};

  @media screen and (min-width: #{$breakpoints-tablet }px) and (orientation:landscape) {
    top: #{$header-height-tablet + rem};
    min-height: calc(100vh - #{$header-height-tablet + rem});
  }

  @media screen and (min-width: #{$breakpoints-desktop }px) and (orientation:landscape) {
    width: #{$navigation-bar-width-desktop + rem};
  }
}

.container {
  @media screen and (min-width: #{$breakpoints-desktop}px) and (orientation : landscape) {
    top: #{$header-height-desktop + rem};
    min-height: calc(100vh - #{$header-height-desktop + rem});
  }
}
.marginIcon {
  @media screen and (orientation: portrait) {
    margin: #{$margins-lightgreen + rem} 0;
  }
}

.isOnTv {
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    top: 0;
    min-height: 100vh;
  }
}

.wrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    display: block;
    color: var(--bottombar-tint-color-inactive);
    transition: all 0.2s;
    /* Animation */
  }

  @media (max-height: 568px) and (orientation: landscape) {
    justify-content: flex-start !important;
  }
  @media (orientation: landscape) {
    flex-direction: column;
    justify-content: center;
  }
  @media screen and (orientation: landscape) and (min-width: #{$breakpoints-desktop}px) {
    svg {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}

@media (orientation: landscape) {
  .container,
  .isOnTv {
    .wrap {
      *:first-child {
        margin-top: auto;
      }

      *:nth-last-child(2) {
        margin-bottom: auto;
      }
    }
  }
}

@media (orientation: landscape) and (min-height: 568px) {
  .container,
  .isOnTv {
    .wrap {
      *:nth-last-child(2) {
        padding-bottom: calc(#{$margins-yellow + rem} + 7.2rem + 1.6rem);
        /* same as account nav link */
      }
    }
  }
}

:global {
  .lowend {
    :local {
      .wrap {
        svg {
          transition: unset;
        }
      }
    }
  }
}
