@import "../../dimens.scss";

.container {
  background-color: var(--card-inactive-background);
  border-radius: 0.4rem;
  box-shadow: 0 0 2.4rem rgba(0, 0, 0, 0.1);
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;

  @media screen and (min-width: 959px) {
    width: 38rem;
  }
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  flex-shrink: 0;
  border-radius: 0.4rem 0 0 0.4rem;
  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: #fff;
  }
  &.success {
    background-color: #77ae44;
  }
  &.error {
    background-color: #c2123b;
  }

  &.info {
    background-color: #088395;
  }
}

.text {
  padding: #{$margins-green + rem};
  @include defaultText();

  @media screen and (min-width: 959px) {
    padding: #{$margins-lightgreen + rem} #{$margins-green + rem};
  }
}
