$breakpoints-tablet: 768;
$breakpoints-desktop: 1280;

$navigation-bar-width-desktop: 9.2;
$navigation-bar-width-phone-portrait: 0;
$navigation-bar-width-phone-landscape: 9.2;

$navigation-bar-height-desktop: 0;
$navigation-bar-height-phone: 5.2;
$navigation-bar-height-tablet: 5.2;

$contentPaddingH-desktop: 6.4;
$contentPaddingH-phone-landscape: 2.4;
$contentPaddingH-phone-portrait: 2.4;

$contentPaddingV-desktop: 3.2;
$contentPaddingV-phone-landscape: 2.4;
$contentPaddingV-phone-portrait: 2.4;

$margins-darkred: 0.8;
$margins-lightgreen: 2.4;
$margins-green: 1.6;
$margins-yellow: 4.8;
$margins-blue: 6.4;
$margins-orange: 3.2;
$margins-bluegreen: 12.8;

$header-height-phone: 4.4;
$header-height-tablet: 4.4;
$header-height-desktop: 3.8;

@mixin defaultText {
  color: var(--text-color);
  font-size: calc(var(--text-size) * 1rem);
  font-weight: var(--text-weight);
  font-family: var(--text-font);
}
