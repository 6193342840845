@import "../../dimens.scss";

.content {
  position: relative;
  width: fit-content !important;
  padding: 4.2rem 11rem !important;
}

.fullscreen {
  width: 100vw !important;
  height: 100vh !important;
  border-radius: unset !important;
  border-top: unset !important;
  max-width: unset !important;
}

.container {
  width: calc(3 * 8rem + 2 * #{$margins-darkred + rem} + 2 * var(--modal-padding));

  @media (orientation: landscape) {
    width: calc(6 * 8rem + 5 * #{$margins-darkred + rem});
    margin: 0 auto;
  }
  @media (min-width: #{$breakpoints-desktop}px) {
    width: calc(6 * 8rem + 5 * #{$margins-darkred + rem} + 2 * var(--modal-padding));
  }
}

.list {
  display: flex;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  padding: 0;
  width: 100%;

  @media (hover: hover) {
    overflow: visible;
  }

  @media (min-width: #{$breakpoints-tablet}px) {
    justify-content: center;
  }

  .listItem {
    background: #f2f2f2;
    background: var(--background);
    border-radius: 47px;
    padding: 10px 8px;
    cursor: pointer;
    list-style: none;
    width: fit-content;

    p {
      color: var(--text-color);
      font-size: 14px;
      margin: 0;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    &.listIem_avatarCurrentCategory {
      p {
        color: var(--accent-color);
      }
    }

    @media (hover: hover) {
      &:hover {
        background: var(--accent-color);
        box-shadow: 0px 0px 12px 0px var(--accent-color);

        p {
          color: #ffffff;
        }
      }
    }
  }
}

.focusAvatar {
  height: 8rem;
  width: 8rem;
  cursor: pointer;

  img {
    border-radius: 50%;
    outline: 0.4rem solid transparent;
  }

  &:focus img,
  &:hover img {
    filter: drop-shadow(0px 0px 12px var(--accent-color));
    outline-color: var(--accent-color);
  }
}
.focusCategory {
  margin-right: 8px;

  &:focus.spatialActive {
    li {
      background-color: var(--accent-color);
      box-shadow: 0px 0px 12px 0px var(--accent-color);

      p {
        color: #ffffff;
      }
    }
  }
}

.separator {
  width: 100%;
  height: 0.1rem;
  background-color: var(--separator-color);
  margin-bottom: #{2 * $margins-green + rem};
}

.avatarContainer {
  grid-gap: #{$margins-darkred + rem};
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 392px) {
    grid-template-columns: repeat(4, 1fr);
  }
  grid-template-rows: repeat(3, 1fr);

  & > div {
    margin: 0 auto;
  }

  @media (orientation: landscape) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: #{$breakpoints-desktop}px) {
    grid-template-columns: repeat(6, 1fr);
  }
}

.imgAvatar {
  width: 100%;
  outline: 4px solid transparent;
  border-radius: 50%;

  &.imgAvatar_active,
  &.imgAvatar_hoverable:hover {
    outline-color: var(--accent-color);
  }
}
