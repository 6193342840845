@import "../../../dimens.scss";

.item {
  padding: #{$margins-green + rem};
  color: var(--text-color);
  border: 1px solid transparent;
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:focus {
    border-color: var(--accent-color);
  }
}

.name {
  font-weight: 600;
}
