.arrowBackWhite {
  svg {
    color: white !important;
  }
}

.arrowBackRed {
  svg {
    color: var(--accent-color) !important;
  }
}
