@import "../../../dimens.scss";

.container {
  background-color: var(--card-inactive-background);
  padding: #{$margins-green + rem};
  position: fixed;
  top: #{header-height-phone} + rem;
  left: #{$navigation-bar-width-phone-portrait} + rem;
  right: 0;
  z-index: 10;
  box-shadow: 0 0 1.2rem silver;
  align-items: center;
  display: flex;
  transition: all 1s; /* Animation */
  fill: var(--text-color);
  @media (orientation: landscape) {
    left: #{$navigation-bar-width-phone-landscape} + rem;
  }
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    top: #{$header-height-desktop} + rem;
    left: #{$navigation-bar-width-desktop} + rem;
  }
}
.button {
  margin-left: auto;
  svg {
    width: 3.2rem;
    height: 3.2rem;
    fill: var(--text-color);
  }
}

.desc {
  color: var(--text-color);
}

.link {
  margin-left: #{$margins-darkred + rem};
  color: var(--accent-color);
}
