.newBadge {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  background-color: var(--accent-color);
  position: absolute;
  right: -0.3rem;
  border: 0.2rem solid #fff;
  top: -0.3rem;
}

@mixin accountActiveState {
  border-color: var(--accent-color);
}

.icon {
  width: 28px;
  height: 28px;
  position: relative;

  @media (min-height: 768px) and (orientation: landscape) {
    display: none;
  }

  svg,
  img {
    border: 0.2rem solid transparent;
    border-radius: 50%;
  }

  @media (hover: hover) {
    &.spatialNavigationActive:hover svg,
    &.spatialNavigationActive:hover img {
      @include accountActiveState();
    }
  }

  &:focus {
    svg,
    img {
      @include accountActiveState();
    }
  }
}
