@import "../../dimens.scss";

.container {
  display: flex;
  flex-direction: column;
  min-height: min-content;
  flex: 1;
  align-items: stretch;
  padding: #{$contentPaddingV-phone-portrait + rem} 0;
  & > * {
    margin: 0 #{$contentPaddingH-phone-portrait + rem} #{$margins-orange + rem};
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: #{$breakpoints-desktop}px) {
    padding: #{$contentPaddingV-desktop + rem} 0;
    & > * {
      margin: 0 #{$contentPaddingH-desktop + rem} #{$margins-orange + rem};
    }
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  align-self: center;
  justify-content: center;
  margin-bottom: #{$margins-green + rem};
  svg {
    width: 80%;
    height: auto;
  }
  button {
    margin-top: #{$margins-green + rem};
  }
  @media (min-width: #{$breakpoints-desktop}px) {
    svg {
      width: 80rem;
    }
  }
  .text {
    font-weight: var(--section-text-weight);
    color: var(--section-text-color);
    font-size: calc(var(--section-text-size) * 1rem);
    font-family: var(--section-text-font);
  }
}

.paginationLoader {
  margin: auto;
  align-self: center;
}

.logo {
  max-height: 42px;
  width: auto;
  margin: #{$margins-green + rem} auto #{$margins-orange + rem} !important/*fix for margin-right: 0 !important on container*/;
  color: var(--text-color);
}

.flat,
.featured {
  &.scrollable {
    margin-left: 0;
    margin-right: 0;
  }
}

.branded {
  margin-left: 0;
  margin-right: 0;
}
