@mixin active {
  transform: scale(var(--card-tile-scaleFactor));
  &:after {
    box-shadow: 0 0 0.6rem var(--card-active-background);
    opacity: 1;
  }
}

.container {
  &.focusable:after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    border: 0.6rem solid var(--card-active-background);
    border-radius: calc(var(--card-tile-radius) * 1rem);
    opacity: 0;
    z-index: 5;
  }
  &.focusable:focus {
    @include active();
  }
  @media (hover: hover) {
    &.focusable:hover {
      @include active();
    }
  }
}
