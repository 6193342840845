@import "../../../../dimens.scss";
.container {
  position: relative;

  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: stretch;
  min-height: min-content; /* LG fix*/

  padding: 0 #{$contentPaddingH-phone-portrait + rem} #{$margins-orange + rem} 0;

  @media (min-width: #{$breakpoints-desktop}px) {
    padding-left: #{$contentPaddingH-desktop + rem};
    padding-right: #{$contentPaddingH-desktop + rem};
  }
}

.brandedViewList {
  padding: #{$margins-blue + rem} 0 #{$margins-green + rem} 0;
}

.arrow {
  svg {
    color: #fff;
  }
}
