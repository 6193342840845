@import "../../dimens.scss";

.page {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  padding: #{$margins-lightgreen + rem};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mainLoading {
  position: absolute;
  top: 85%;
  left: 50%;
  margin-left: -25px;
}

.resetPinLoading {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -10px;
}

.forgotPasswordContainer {
  position: relative;
  margin-top: #{$margins-yellow + rem};
  display: flex;
  padding: 0 30px;
}

.profile {
  margin: #{$margins-green + rem};
}
.pin {
  margin-top: #{$margins-yellow - $margins-darkred + rem};
  margin-bottom: #{$margins-green + rem};
  min-height: min-content;
}

.error {
  color: var(--error-color);
  margin-bottom: #{$margins-green + rem};
}

.subtile {
  color: var(--text-color);
  text-align: center;
}
