@import "../../../dimens.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: min-content;
}

.subtitle {
  @include defaultText();
  text-align: center;
  margin-bottom: #{$margins-green + rem};
}
