@font-face {
    font-family: "dotsfont";
    src: url("./assets/dist/fonts/dotsfont/dotsfont.eot");
    src: url("./assets/dist/fonts/dotsfont/dotsfont.eot?#iefix") format("embedded-opentype"),
        url("./assets/dist/fonts/dotsfont/dotsfont.woff") format("woff"),
        url("./assets/dist/fonts/dotsfont/dotsfont.ttf") format("truetype"),
        url("./assets/dist/fonts/dotsfont/dotsfont.svg#dotsfontregular") format("svg");
}

/*-------------------------------------------------------------------------
General
-------------------------------------------------------------------------*/
html,
body {
    height: 100vh;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 10px;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    /* Prevent font scaling in landscape while allowing user zoom */
}

body {
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, "Helvetica Neue", Arial, sans-serif !important;
    margin: 0;
    font-size: 1.6rem;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
    overflow-y: overlay;
}

a {
    color: inherit;
}

* {
    box-sizing: border-box;
}

/* Scrollbar reset FIXME */
::-webkit-scrollbar {
    width: 0rem;
    height: 0rem;
    background-color: initial;
    display: none;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-corner {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
    background: #cfcfcf;
}

*:focus {
    outline: none;
}

img,
a {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

a {
    user-select: none;
}

span,
h1,
h2,
label {
    cursor: default;
}

span,
h1,
h2,
button,
canvas,
label {
    user-select: none;
}

button>span,
a>span {
    cursor: pointer;
}

textarea,
button {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
}