@import "../../../dimens.scss";
.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
  min-height: min-content;
}

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: #{$margins-darkred + rem};
  &.scrollable {
    padding: 0 #{$contentPaddingV-phone-portrait + rem}; /*Extra padding added since margin = 0*/
  }
}

.title {
  font-weight: var(--section-text-weight);
  color: var(--section-text-color);
  font-size: calc(var(--section-text-size) * 1rem);
  font-family: var(--section-text-font);
  display: flex;
  align-items: center;

  > svg {
    display: none;
  }

  &.titleClickable {
    cursor: pointer;

    > svg {
      display: inline;
      width: 1rem;
      height: 1rem;
      margin-left: 0.4rem;

      @media screen and (min-width: #{$breakpoints-desktop}px) {
        display: none;
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    &:hover,
    &:focus {
      color: var(
        --accent-color
      ) !important; //use important to override section title color as style

      > svg {
        display: inline;
      }
    }
  }
}

.titleBranded {
  &.titleClickable {
    &:hover,
    &:focus {
      color: white;
    }
  }
}

.list {
  display: flex;
  margin: 0;
  list-style: none;
  padding: 0;
  min-height: min-content;
  & > * {
    min-height: min-content;
    margin-right: #{$margins-darkred + rem};
    &:last-child {
      margin-right: 0;
    }
  }
}

.listContainer {
  display: flex;
  max-width: 100vw;
  &.scrollable {
    &::before,
    &::after {
      content: "";
      min-width: 2.4rem; // This is the extra padding
    }
    overflow-y: hidden;
    overflow-x: scroll;
    overflow-x: overlay;
    -webkit-overflow-scrolling: touch;
    // Fix for clipping vertically when focused
    padding: 10px 0;
    margin: -10px 0;
    scroll-snap-type: x proximity;
  }

  @media (orientation: landscape) {
    max-width: calc(100vw - #{$navigation-bar-width-phone-landscape + rem});
  }
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    max-width: calc(100vw - #{$navigation-bar-width-desktop + rem});
  }
}
