@import "../../dimens.scss";

.button {
  width: fit-content;
  height: fit-content;
  border: 0.2rem solid transparent;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: var(--text-color);
  }

  &:hover {
    svg {
      fill: var(--accent-color);
    }
  }

  @media (min-width: #{$breakpoints-tablet}px) {
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;

    svg {
      width: 2.8rem;
      height: 2.8rem;
    }
  }
}
