@import "../../dimens.scss";

.container {
  flex: 1;
  flex-direction: column;
  padding: #{$margins-lightgreen + rem};
  display: flex;
  justify-content: center;
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    padding: 0 #{$margins-bluegreen + rem};
    max-width: 88rem;
    min-width: 70rem;

    margin: auto;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: #{$margins-green + rem};

  flex-direction: row;
  max-width: calc(2 * 12rem + 2 * 2 * #{$margins-darkred + rem});
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    max-width: calc(2 * 20rem + 2 * 2 * #{$margins-darkred + rem});
  }

  margin: 0 auto;
}
