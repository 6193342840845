@import "../../dimens.scss";

.page {
  background-color: var(--background);
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.title {
  font-weight: var(--title-text-weight);
  color: var(--title-text-color);
  font-size: calc(var(--title-text-size) * 1rem);
  font-family: var(--title-text-font);

  text-align: center;
  margin-top: 0;
  margin-bottom: #{$margins-lightgreen + rem};
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    margin-bottom: #{$margins-yellow + rem};
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 #{$margins-lightgreen + rem};
  margin-top: #{$header-height-phone + rem};
  justify-content: center;
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    margin-top: #{$header-height-desktop + rem};
  }
}

.profilesContainer {
  display: grid;
  grid-gap: #{$margins-darkred + rem};
  grid-template-columns: repeat(2, 1fr);
  @media (orientation: landscape) {
    display: flex;
    grid-gap: 0;
    & > * {
      margin: 0 #{$margins-darkred + rem};
    }
  }
}

.item {
  list-style: none;
  display: flex;

  @media (orientation: landscape) and (max-width: #{$breakpoints-tablet}px) {
    div {
      width: 100%;
      min-width: 8rem;
    }
  }
}

.initialLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.loading {
  position: absolute;
  top: 85%;
  left: 50%;
  margin-left: -25px;
}
