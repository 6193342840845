@import "../../dimens.scss";

.loading {
  margin: auto;
  align-self: center;
}

.container {
  display: flex;
  flex-direction: column;
  padding: #{$contentPaddingV-phone-portrait + rem} #{$contentPaddingH-phone-portrait + rem};
  flex: 1;
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    padding: #{$contentPaddingV-desktop + rem} #{$contentPaddingH-desktop + rem};
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  & > * {
    margin-bottom: #{$margins-green + rem};
    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (orientation: landscape) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  @media screen and (min-height:  #{$breakpoints-tablet}px) and (orientation: landscape) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  @media screen and (min-width: #{$breakpoints-tablet}px) and (orientation: portrait) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }

  @media screen and (min-width: #{$breakpoints-desktop}px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
  }
}
