.container {
  flex: 1;
  display: flex;
  background-color: var(--background);
}

.loading {
  margin: auto;
  align-self: center;
}
