@import "../../dimens.scss";
@import "../Tile//styles.common.module.scss";

.container {
  cursor: pointer;
  position: relative;
  border-radius: calc(var(--card-tile-radius) * 1rem);
  user-select: none;
  flex-shrink: 0;
  transition: transform 0.2s; /* Animation */
  width: calc(
    100vw - #{$navigation-bar-width-phone-portrait + rem} - #{2 * $contentPaddingH-phone-portrait +
      rem}
  );
  height: calc(
    (
        100vw - #{$navigation-bar-width-phone-portrait + rem} - #{2 * $contentPaddingH-phone-portrait +
          rem}
      ) / 3.18
  );

  @media screen and (orientation: landscape) {
    width: calc(
      100vw - #{$navigation-bar-width-phone-landscape + rem} - #{2 * $contentPaddingH-phone-landscape +
        rem}
    );
    height: calc(
      (
          100vw - #{$navigation-bar-width-phone-landscape + rem} - #{2 * $contentPaddingH-phone-landscape +
            rem}
        ) / 3.18
    );
  }

  @media screen and (min-width: #{$breakpoints-tablet}px) and (orientation: portrait) {
    width: calc(
      100vw - #{$navigation-bar-width-phone-portrait + rem} - #{2 * $contentPaddingH-phone-portrait +
        rem}
    );
    height: calc(
      (
          100vw - #{$navigation-bar-width-phone-portrait + rem} - #{2 * $contentPaddingH-phone-portrait +
            rem}
        ) * 3.7 / 16
    );
  }

  @media screen and (min-height: #{$breakpoints-tablet}px) and (orientation: landscape) {
    width: calc(
      100vw - #{$navigation-bar-width-phone-landscape + rem} - #{2 * $contentPaddingH-phone-landscape +
        rem}
    );
    height: calc(
      (
          100vw - #{$navigation-bar-width-phone-landscape + rem} - #{2 * $contentPaddingH-phone-landscape +
            rem}
        ) * 3.7 / 16
    );
  }

  @media screen and (min-width: #{$breakpoints-desktop}px) {
    width: calc(
      100vw - #{$navigation-bar-width-desktop + rem} - #{2 * $contentPaddingH-desktop + rem}
    );
    height: calc(
      (
          100vw - #{$navigation-bar-width-desktop + rem} - #{2 * $contentPaddingH-desktop +
            rem}
        ) * 3.7 / 16
    );
  }
}

.image {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: calc(var(--card-tile-radius) * 1rem);
  overflow: hidden;
  img {
    height: 100% !important;
    object-fit: cover;
  }
}
