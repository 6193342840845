@import "../../dimens.scss";

@mixin active-state {
  svg,
  img {
    border-color: var(--bottombar-tint-color-active);
  }
}

.iconProfileMenu {
  display: none !important;
}

.focusable {
  display: none !important;
  /*since lemag*/

  svg,
  img {
    border: 0.2rem solid transparent;
    border-radius: 50%;
    //Since border override size
    width: 2.8rem !important;
    height: 2.8rem !important;
  }

  @media (min-height: 568px) and (orientation: landscape) {
    margin-top: #{$margins-yellow + rem};
    order: -1;
    display: flex !important;
    .link {
      flex: 1;
    }
    //Since border override size
    svg,
    img {
      width: 4rem !important;
      height: 4rem !important;
    }
  }

  &:focus {
    .link.spatialActive {
      @include active-state();
    }
  }
}

.link {
  &.active {
    @include active-state();
  }

  @media (hover: hover) {
    &:hover {
      @include active-state();
    }
  }
}
