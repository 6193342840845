$iconHeight: 1.8;
$padding: 0.8;
@import "../../dimens.scss";

.container {
  display: none;
  @media (min-width: #{$breakpoints-desktop}px) {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    color: #fff;
    background-color: var(--accent-color);
    padding: #{$padding + rem};
    line-height: #{$iconHeight + rem};
    @media (hover: hover) {
      &:hover {
        div {
          max-width: 100px;
          padding-right: #{$margins-darkred + rem};
        }
      }
    }
  }
}
.active {
  opacity: 1;
}
.text {
  max-width: 0;
  width: auto;
  overflow: hidden;
  font-weight: 600;
  transition: max-width 700ms ease-out;
  white-space: nowrap;
}
.icon {
  height: #{$iconHeight + rem};
  width: auto;
  flex-shrink: 0;
}
