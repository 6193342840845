.container {
  overflow: hidden;
  position: relative;
  display: flex;
}

.ripple {
  width: 20px;
  height: 20px;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  display: block;
  content: "";
  border-radius: 9999px;
  opacity: 1;
  :global {
    animation: 1.5s ease 1 forwards ripple-effect;
  }
}

@keyframes :global(ripple-effect) {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(100);
    opacity: 0.3;
  }
  100% {
    transform: scale(1000);
    opacity: 0;
  }
}

.content {
  position: relative;
  flex: 1;
  display: flex;
}
