@import "../../dimens.scss";

.container {
  background-color: var(--background);
  color: var(--text-color);
  padding: #{$margins-lightgreen + rem};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logoContainer {
  margin: #{$margins-lightgreen + rem} auto;
  svg {
    vertical-align: middle;
  }
}

.content {
  margin: auto;

  display: flex;
  flex-direction: column;
  text-align: center;
}

.title {
  font-weight: var(--title-text-weight);
  color: var(--title-text-color);
  font-size: calc(var(--title-text-size) * 1rem);
  font-family: var(--title-text-font);
  margin-bottom: #{$margins-lightgreen + rem};
  line-height: normal;
}

.subtitle {
  font-weight: var(--text-weight);
  color: var(--text-color);
  font-size: calc(var(--text-size) * 1rem);
  font-family: var(--text-font);
  margin-bottom: #{$margins-lightgreen + rem};
  line-height: normal;
}

.imageContainer {
  margin: 0 auto;
  margin-bottom: #{$margins-lightgreen + rem};
  background: var(--card-inactive-background);
  padding: 2.8rem 3.5rem;
  border-radius: 1.2rem;

  svg {
    width: 9.6rem;
    color: var(--accent-color);
  }
}

.btnPrimary {
  margin-bottom: #{$margins-lightgreen + rem};
  min-width: 200px;
}

.btnSecondary {
  min-width: 200px;
}
