.placeholder {
  border-radius: calc(var(--card-radius) * 1rem);
  overflow: hidden;
  background-color: #bababa;
  display: flex;
  width: 100%;
  height: 100%;
  svg {
    color: #fff;
    margin: auto;
  }
}

.image {
  border-radius: calc(var(--card-radius) * 1rem);
  overflow: hidden;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
