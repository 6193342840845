:global {
  .lowend {
    :local {
      .featuredTile,
      .standardTile {
        transition: unset;
      }
    }
  }
}
