@import "../../../dimens.scss";

.list {
  margin: 0;
  list-style: none;
  padding: 0;
}

.item {
  padding: #{$margins-darkred + rem};
  cursor: pointer;
  color: var(--modal-text-color);
  display: flex;
  align-items: center;
  margin: 0 -#{$margins-darkred + rem};
  border: 0.1rem solid transparent;
  border-radius: calc(var(--card-radius) * 1rem);
  .hoveredIcon {
    display: none;
  }
  .icon {
    display: block;
  }
  &.active {
    color: var(--accent-color);
  }
  &:focus,
  &:hover {
    border-color: var(--accent-color);
    background-color: var(--accent-color);
    color: white;

    .hoveredIcon {
      display: block;
    }
    .icon {
      display: none;
    }
  }
  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: #{$margins-darkred + rem};
  }
  span {
    cursor: pointer;
  }
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    svg {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}
