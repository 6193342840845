@import "../../dimens.scss";
.container {
  flex: 1;
  flex-direction: column;
  padding: #{$margins-lightgreen + rem};
  display: flex;
  justify-content: center;

  @media (min-width: #{$breakpoints-desktop}px) {
    padding: 0 #{$margins-bluegreen + rem};
    max-width: 88rem;
    min-width: 70rem;
    margin: auto;
  }
}

.profilesContainer {
  display: flex;
  flex-direction: column;
  & > * {
    margin: #{$margins-darkred + rem} 0;
  }
}

@mixin ActiveState {
  .editIcon,
  .nickname,
  .decoration,
  .lock {
    color: var(----card-cardStyle-textColor);
  }

  .lock {
    svg:first-child {
      display: none;
    }
    svg:last-child {
      display: inline;
    }
  }
}

.profile {
  .editIcon {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: auto;
    margin-right: 0;
    color: var(--accent-color);
  }
  @media (hover: hover) {
    &:hover {
      @include ActiveState();
    }
  }
  &:focus {
    @include ActiveState();
  }
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    .editIcon {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}

.nickname {
  @include defaultText();
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .nicknameLockContainer {
    display: flex;
  }
}

.decoration {
  color: var(--text2-color);
  font-size: calc(var(--text2-size) * 1rem);
  font-weight: var(--text2-weight);
  font-family: var(--text2-font);
}
.lock {
  color: var(--text2-color);
  display: flex;
  align-items: center;

  svg {
    width: 1.4rem !important; //grr see ProfileListItem2
    height: 1.4rem !important;
  }
  margin-left: #{$margins-darkred + rem};

  svg:first-child {
    display: inline;
  }
  svg:last-child {
    display: none;
  }
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-fill-available;
}
.editIconContainer {
  display: flex;
}
