@import "../../dimens.scss";
@import "../Tile/styles.common.module.scss";

.container {
  align-self: auto;
  cursor: pointer;
  position: relative;
  user-select: none;
  transition: transform 0.2s; /* Animation */

  width: calc(
    100vw - #{$navigation-bar-width-phone-portrait + rem} - #{2 * $contentPaddingH-phone-portrait +
      rem}
  );
  height: calc(
    (
        100vw - #{$navigation-bar-width-phone-portrait + rem} - #{2 * $contentPaddingH-phone-portrait +
          rem}
      ) * (9 / 16)
  );
  border-radius: calc(var(--card-tile-radius) * 1rem);

  @media (orientation: landscape) {
    width: calc(
      100vw - #{$navigation-bar-width-phone-landscape + rem} - #{2 * $contentPaddingH-phone-landscape +
        rem}
    );
    height: calc(
      (
          100vw - #{$navigation-bar-width-phone-landscape + rem} - #{2 * $contentPaddingH-phone-landscape +
            rem}
        ) * 0.318
    );
  }

  @media screen and (min-width: #{$breakpoints-desktop}px) {
    width: calc(
      100vw - #{$navigation-bar-width-desktop + rem} - #{2 * $contentPaddingH-desktop + rem}
    );
    height: calc(
      (
          100vw - #{$navigation-bar-width-desktop + rem} - #{2 * $contentPaddingH-desktop +
            rem}
        ) * 0.39
    );
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: calc(var(--card-tile-radius) * 1rem);
  overflow: hidden;
  @media screen and (min-width: #{$breakpoints-tablet}px) {
    height: 100% !important;
    object-fit: cover;
  }
}

.title {
  display: block;
  margin-top: auto;
  color: #fff;

  font-weight: var(--title-text-weight);
  font-size: 3.6rem;

  @media screen and (min-width: #{$breakpoints-desktop}px) {
    margin-bottom: 4rem;
  }
}

.video {
  object-fit: cover;
  border-radius: calc(var(--card-tile-radius) * 1rem);
  overflow: hidden;
  z-index: 4;
  display: none;
  video {
    width: 100%;
  }
  margin-left: auto;
  @media screen and (min-width: #{$breakpoints-tablet}px) and (orientation: landscape) {
    display: block;
  }
  @media screen and (min-width: #{$breakpoints-desktop}px) {
    display: block;
  }
}

.video.playing {
  box-shadow: 0 0 1.2rem #000;
}

.content {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: #{$margins-lightgreen + rem};
  z-index: 4;
  align-items: center;

  border-radius: calc(var(--card-tile-radius) * 1rem);
}

.content.hasTitle {
  background: linear-gradient(
    312.24deg,
    rgba(0, 0, 0, 0.35) 27.28%,
    rgba(0, 0, 0, 0.05) 83.89%
  );
}

:global {
  .lowend {
    :local {
      .container {
        transition: unset;
      }
    }
  }
}
