@import "../../../dimens.scss";

@mixin desktop-active-state {
  background-color: var(--bottombar-tint-color-active);
  box-shadow: 0 0 1.2rem var(--bottombar-tint-color-active);

  svg {
    color: var(--bottombar-tint-color-active-focused);
  }
}

.focusable {
  display: none;

  @media (min-width: #{$breakpoints-desktop}px) {
    margin-left: #{$margins-darkred + rem};
    color: var(--accent-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    padding: #{$margins-green + rem};
    cursor: pointer;
    border-radius: calc(var(--card-radius) * 1rem);
    background-color: color-mix(in srgb, var(--separator-color) 30%, transparent);
    &:hover {
      @include desktop-active-state();
    }
    &:focus {
      &.spatialActive {
        @include desktop-active-state();
      }
    }
  }
}

:global {
  .lowend {
    :local {
      .focusable {
        transition: unset;
      }
    }
  }
}
